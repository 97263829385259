

















































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import agent from "@/helpers/agent";
import {
  ApplicationStatus,
  BusinessProfileVM,
  BusinessType,
} from "@/models/businessApplication";
import { RawLocation } from "vue-router";
import Comments from "@/components/Comments.vue";
import { CommentType } from "@/models/comment";
import { namespace } from "vuex-class";
import Notifier from "@/helpers/notifier";
import { AppUser } from "@/models/user";

const app = namespace("app");

interface ProfileStatus {
  id: number;
  name: string;
  status: string;
  location: RawLocation;
}
@Component({ components: { Comments } })
export default class BusinessProfile extends Vue {
  @app.Getter role!: string;
  @app.Getter user!: AppUser;

  step = 1;
  businessProfile: BusinessProfileVM = {
    id: "",
    businessName: "",
    type: BusinessType.SoleTrader,
    reference: "",
    status: ApplicationStatus.Pending,
    legalProfileStatus: "",
    businessProfileStatus: "",
    formRStatus: "",
    formJStatus: "",
    bankingProfileStatus: "",
    additionalProfileStatus: "",
    isIndividual: true,
  };
  loading = true;
  showDialog = false;
  confirm = false;
  actionFab = false;
  commentType = CommentType.Application;
  columns = [
    {
      name: "name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: ProfileStatus) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: ProfileStatus) => row.status,
      sortable: true,
    },
  ];
  data: ProfileStatus[] = [];
  get status() {
    return ApplicationStatus[this.businessProfile.status];
  }
  get approvedStatus() {
    return ApplicationStatus.Approved;
  }
  get ApproveLabel() {
    switch (this.role) {
      case "User":
        return "Submit to manager";
      case "Manager":
        return "Submit to central bank";
      case "Reviewer":
        return "Submit for approval";
      default:
        return "Approve";
    }
  }
  get isNotUser(): boolean {
    return this.user.role !== "User";
  }
  get showApprove() {
    if (this.businessProfile.status === ApplicationStatus.Rejected)
      return false;

    if (this.user.role === "User") {
      return this.businessProfile.status === ApplicationStatus.Pending;
    } else if (this.user.role === "Manager") {
      return this.businessProfile.status === ApplicationStatus.PendingReview;
    } else if (this.user.role === "Reviewer") {
      return (
        this.businessProfile.status === ApplicationStatus.SubmittedToCentralBank
      );
    } else {
      return this.businessProfile.status === ApplicationStatus.Reviewed;
    }
  }
  getColor(status: string) {
    if (
      status.toLowerCase().includes("all") ||
      status.toLowerCase().includes("submitted")
    )
      return "positive";

    if (status.toLowerCase().includes("missing")) return "orange";

    return "red";
  }
  approve() {
    this.loading = true;
    switch (this.role) {
      case "User":
        agent.Applications.submitToManager(this.$route.query.id as string)
          .then(() =>
            Notifier.showPositive(
              "Application submitted to manager for approval"
            )
          )
          .finally(() => (this.loading = false));
        break;
      case "Manager":
        agent.Applications.submitForReview(this.$route.query.id as string)
          .then(() =>
            Notifier.showPositive("Application submitted to Central Bank")
          )
          .finally(() => (this.loading = false));
        break;
      case "Reviewer":
        agent.Applications.submitForApproval(this.$route.query.id as string)
          .then(() =>
            Notifier.showPositive("Application submitted for approval")
          )
          .finally(() => (this.loading = false));
        break;

      default:
        this.$router
          .push({
            path: "/approve-application",
            query: {
              id: this.$route.query.id,
              reference: this.businessProfile.reference,
              businessName: this.businessProfile.businessName,
            },
          })
          .finally(() => (this.loading = false));
        break;
    }
  }
  mounted() {
    if (this.$route.query.id) {
      agent.Applications.getProfile(this.$route.query.id as string).then(
        (businessProfile) => {
          this.businessProfile = businessProfile;
          this.data = [
            {
              id: 1,
              name: "Business Profile",
              status: this.businessProfile.businessProfileStatus,
              location: {
                path: "/business-documents",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  type: `${this.businessProfile.type}`,
                  step: "1",
                },
              },
            },
            {
              id: 2,
              name: "Legal Profile",
              status: this.businessProfile.legalProfileStatus,
              location: {
                path: "/legal-documents",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  type: `${this.businessProfile.type}`,
                  step: "1",
                },
              },
            },
            {
              id: 3,
              name: "Banking Profile",
              status: this.businessProfile.bankingProfileStatus,
              location: {
                path: "/banking-documents",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  type: `${this.businessProfile.type}`,
                  step: "1",
                },
              },
            },
            {
              id: 4,
              name: "Form R",
              status: this.businessProfile.formRStatus,
              location: {
                path: "/formr-forms",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  type: `${this.businessProfile.type}`,
                  step: "1",
                },
              },
            },
            {
              id: 5,
              name: "Form T",
              status: this.businessProfile.formRStatus,
              location: {
                path: "/formt-forms",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  type: `${this.businessProfile.type}`,
                  step: "1",
                },
              },
            },
            // {
            //   id: 5,
            //   name: "Form J",
            //   status: this.businessProfile.formJStatus,
            //   location: {
            //     path: "/formj-forms",
            //     query: {
            //       id: this.$route.query.id,
            //       reference: this.businessProfile.reference,
            //       businessName: this.businessProfile.businessName,
            //     },
            //   },
            // },
            {
              id: 6,
              name: "Additional",
              status: this.businessProfile.additionalProfileStatus,
              location: {
                path: "/additional-documents",
                query: {
                  id: this.$route.query.id,
                  reference: this.businessProfile.reference,
                  businessName: this.businessProfile.businessName,
                  step: "1",
                },
              },
            },
          ];
          this.loading = false;
        }
      );
    }
  }
}
